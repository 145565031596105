.header img {
  width: 50px;
  height: 50px;
}

.home {
  margin: 20px;
  margin-right: 50px;
}
.home p {
  font-size: 125%;
}

:root {
  --amplify-primary-color: #446eb2;
  --amplify-primary-tint: #67a9c4;
  --amplify-primary-shade: #b9b9b9;
  background: #f0f2f5;
}

amplify-authenticator {
  --container-height: auto;
}

.amplify-logo {
  width: 28.75rem;
}
@media only screen and (max-width: 671px) {
  .amplify-logo {
    width: 20rem;
  }
}

[data-amplify-authenticator] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
