#header {
  border-bottom: 2px solid black;
  margin-bottom: 5px;
}

#header * {
  color: black;
  padding-right: 15px;
}

.hyperRemove * {
  text-decoration: none;
  color: black;
}

.sideBar a {
  border-bottom: 1px solid black;
  margin-left: -2%;
}

.align {
  display: flex;
}

.alignContent {
  flex: 1;
}

#recruiterBack {
  background-image: linear-gradient(to right, #e06ebd, white);
  height: 50%;
}

#playerBack {
  background-image: linear-gradient(to right, #268ce0, white);
  height: 50%;
}

#coachBack {
  background-image: linear-gradient(to right, #e02426, white);
  height: 50%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-content: center;
}

.logo img {
  width: 50px;
  height: 50px;
  bottom: 50px;
}

.siderSpace {
  padding-top: 5px;
  overflow: auto;
  height: 100vh;
  /*position: fixed;*/

  left: 0;
  /*box-shadow: 0px 0px 8px #999;
    background: #eee;*/
  padding-left: 20px;
}

.ant-layout-content {
  /*background: #fff;*/
  margin-left: 10px;
}

.ant-menu-inline .ant-menu-submenu-title {
  font-weight: bold;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
}
