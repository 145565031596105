body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border-radius: 3px;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.sortableList p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  margin: 0;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}

/* Hide the default Ant Design spinner */
.ant-spin-dot {
  display: none !important;
}

/* Custom spinner styles */
.ant-spin-spinning {
  position: relative;
  width: 40px; /* Adjust to match your custom spinner size */
  height: 40px; /* Adjust to match your custom spinner size */
}

/* Insert custom spinner image */
.ant-spin-spinning::after {
  content: "";
  position: absolute;
  top: 5%;
  left: 50%;
  background: url("../public/playMakerLogoSmall.jpg") no-repeat center center;
  width: 53px; /* Adjust to your image's actual width */
  height: 53px; /* Adjust to your image's actual height */
  background-size: contain;
  animation: spin 2s linear infinite;
}

/* Keyframes for spinning effect */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
