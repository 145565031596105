table.ngstatsTable {
  border-spacing: 10px;
  border-collapse: separate;
}

.ngstatsTable td,
.ngstatsTable th {
  border-bottom: 1px solid #ddd;
  padding: 5px 5px 2px;
}
